.section_type_main {
  flex-basis: 485px;
  flex-shrink: 0;
  padding-bottom: 64px;
}
.section__heading {
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}
.section__subheading {
  font-size: 24px;
  text-align: center;
  color: #9c9c9c;
}
.section__heading + .section__subheading {
  margin-top: 24px;
}
.section__heading + .section__content, .section__content + .section__content {
  margin-top: 32px;
}
.section__subheading + .section__content, .section__subheading + .section__text {
  margin-top: 16px;
}
.section__content + .section__subheading {
  margin-top: 48px;
}
.section__heading-highlighter {
  position: relative;
  display: inline-block;
}
.section__text {
  margin: 32px auto;
  font-size: 18px;
  text-align: center;
}
.section__text_type_sparse {
  line-height: 36px;
}
.section__text_type_tight {
  line-height: 28px;
}
.section__text_align_left {
  text-align: left;
}
.section__text_size_medium {
  font-size: 16px;
}
.section__text:last-child {
  margin-bottom: 0;
}
.section__nav {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0 32px;
}
.section__nav > a.current {
  font-weight: bold;
}
@media (min-width: 768px) {
  .section_type_main {
    padding: 140px 0 96px;
    flex-basis: 540px;
  }
  .section__heading {
    text-align: center;
  }
  .section__heading_size_large {
    font-size: 40px;
    font-weight: 300;
  }
  .section__nav {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .section__text {
    max-width: 800px;
  }
  .section__text_width_full {
    max-width: 100%;
  }
}

.error-404 {
  min-height: 320px;
}
.error-404 > svg {
  display: block;
  margin: 0 auto;
  width: 320px !important;
  height: 320px !important;
}

.tabs__radio {
  display: none;
}

.tabs__radio ~ .tabs__content {
  display: none;
}

.tabs__radio.tabs__radio_index_0:checked ~ .tabs__content.tabs__content_index_0 {
  display: block;
}

.tabs__radio.tabs__radio_index_1:checked ~ .tabs__content.tabs__content_index_1 {
  display: block;
}

.tabs__radio.tabs__radio_index_2:checked ~ .tabs__content.tabs__content_index_2 {
  display: block;
}

.tabs__radio.tabs__radio_index_3:checked ~ .tabs__content.tabs__content_index_3 {
  display: block;
}

.tabs__radio.tabs__radio_index_4:checked ~ .tabs__content.tabs__content_index_4 {
  display: block;
}

.tabs__radio.tabs__radio_index_5:checked ~ .tabs__content.tabs__content_index_5 {
  display: block;
}

.tabs__radio.tabs__radio_index_6:checked ~ .tabs__content.tabs__content_index_6 {
  display: block;
}

.tabs__radio.tabs__radio_index_7:checked ~ .tabs__content.tabs__content_index_7 {
  display: block;
}

.tabs__radio.tabs__radio_index_8:checked ~ .tabs__content.tabs__content_index_8 {
  display: block;
}

.tabs__radio.tabs__radio_index_9:checked ~ .tabs__content.tabs__content_index_9 {
  display: block;
}

.tabs__radio.tabs__radio_index_10:checked ~ .tabs__content.tabs__content_index_10 {
  display: block;
}

.tabs__radio.tabs__radio_index_11:checked ~ .tabs__content.tabs__content_index_11 {
  display: block;
}

.tabs__radio.tabs__radio_index_12:checked ~ .tabs__content.tabs__content_index_12 {
  display: block;
}

.tabs__radio.tabs__radio_index_13:checked ~ .tabs__content.tabs__content_index_13 {
  display: block;
}

.tabs__selector {
  font-size: 18px;
  padding-bottom: 4px;
  color: #5b5b5d;
  border-bottom: 2px solid;
  margin: 0 8px;
  cursor: pointer;
  line-height: 36px;
}
.tabs__selector:hover {
  color: #f27135;
}

.tabs__radio:checked + .tabs__selector {
  color: #f27135;
  border-color: #f27135;
}

.section__heading {
  margin-bottom: 16px;
}

.error-404 {
  margin-bottom: 64px;
}